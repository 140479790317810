<template>
  <BrandButton :radius="50" shape="rounded" @click="goToPlayable" class="weekly-playable-button">
    <VueText :isSingleLine="true" color="white-100" sizeLevel="3">Oyna</VueText>
  </BrandButton>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';

export default {
  name: 'WeeklyPlayableForRosette',
  components: { VueText, BrandButton },
  props: {
    rosette: {
      type: Object,
    },
  },
  methods: {
    goToPlayable() {
      this.$emit('goToPlayable');
    },
  },
};
</script>
<style scoped lang="scss">
.weekly-playable-button {
  height: 35px !important;
}
</style>
