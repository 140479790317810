<template>
  <div style="padding:0 15% !important;">
    <NpsSurveyForRosettes
      v-if="isVisibleJoinNow(rosette)"
      @openNpsSurveyPopup="openNpsSurveyPopup"
      :rosette="rosette"
    />
    <VirtualVisitForRosettes
      v-if="isVirtualVisit(rosette)"
      @openVirtualVisit="openVirtualVisit"
      :rosette="rosette"
    />
    <EOrderForRosettes v-if="isEOrder(rosette)" :rosette="rosette" @openEOrder="openEOrder" />
    <WeeklyPlayableForRosette
      v-if="isVisibleWeeklyPlayable(rosette)"
      @goToPlayable="goToPlayable"
      :rosette="rosette"
    />
  </div>
</template>
<script>
import RosetteInfo from '@/mixins/rosetteInfo.js';
import NpsSurveyForRosettes from './NpsSurveyForRosettes.vue';
import WeeklyPlayableForRosette from './WeeklyPlayableForRosette.vue';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import VirtualVisitForRosettes from './VirtualVisitForRosettes.vue';
import EOrderForRosettes from './EOrderForRosettes.vue';
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'RouterFromRosettes',
  mixins: [RosetteInfo, gtmUtils],
  components: {
    NpsSurveyForRosettes,
    WeeklyPlayableForRosette,
    EOrderForRosettes,
    VirtualVisitForRosettes,
  },
  props: {
    rosette: {
      type: Object,
    },
    hasActiveMarathons: {
      type: Boolean,
    },
    hasNpsSurvey: {
      type: Boolean,
    },
  },
  computed: {
    isVisibleWeeklyPlayable() {
      return rosette => {
        return this.checkVisibility(rosette, 10) && this.hasActiveMarathons;
      };
    },
    isVisibleJoinNow() {
      return rosette => {
        return this.checkVisibility(rosette, 5) && this.hasNpsSurvey;
      };
    },
    isVirtualVisit() {
      return rosette => {
        return this.checkVisibility(rosette, 4);
      };
    },
    isEOrder() {
      return rosette => {
        return this.checkVisibility(rosette, 9);
      };
    },
  },
  methods: {
    checkVisibility(rosette, typeId) {
      return rosette?.rosetTypeId === typeId && this.rosette.isSuccessible === 1;
    },
    goToPlayable() {
      const route = `${RoutesRoot.Secure.path}/${RoutesSecure.WeeklyPlayable.path}`;
      this.$router.push({ path: route });
    },
    openVirtualVisit() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sef_dukkaninda', {
        location: 'rozetlerim',
      });
      const route = `${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`;
      this.$router.push({ path: route });
    },
    openNpsSurveyPopup() {
      this.$emit('openNpsSurveyPopup');
    },
    openEOrder() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('on_siparis_click', {
        location: 'rozetlerim',
      });
      Common.getEcommerceUrl().then(res => {
        const { url } = res.data.Data;
        if (!url) return;
        window.location = url;
      });
    },
  },
};
</script>
