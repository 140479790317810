<template>
  <BrandButton :radius="50" shape="rounded" @click="openNpsSurveyPopup" class="nps-survey-button">
    <VueText :isSingleLine="true" color="white-100" sizeLevel="3">Katıl</VueText>
  </BrandButton>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
export default {
  name: 'NpsSurveyForRosettes',
  components: { VueText, BrandButton },
  props: {
    rosette: {
      type: Object,
    },
  },
  methods: {
    openNpsSurveyPopup() {
      this.$emit('openNpsSurveyPopup');
    },
  },
};
</script>
<style scoped lang="scss">
.nps-survey-button {
  height: 35px !important;
}
</style>
